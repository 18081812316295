.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul {
  padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal;
}
.ck.ck-content ol {
  padding-left: 40px;
}

.ck.ck-content h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.ck.ck-content h3 {
  font-size: 1.25rem;
  font-weight: bold;
}

.ck.ck-content h4 {
  font-size: 1rem;
  font-weight: bold;
}
